import React, { Suspense, useContext, useMemo, useState } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import { OtherRoles, SchoolRoles } from '../utils/constants'
import { Routes } from '../routes'
import { getUser } from '../reducers/UserReducer'
import ShiftPresetsManager from '../Views/shared/ShiftsPresetsManagerView/ShiftPresetsManager'
import ChangeLogView from '../Views/shared/ChangeLogView'
import TagsManagementView from '../Views/shared/TagsManagementView'
import ShiftsManagerView from '../Views/institution/InstitutionShiftManager'
import { isArray } from 'lodash'
import { connect } from 'react-redux'
import InstitutionEvaluationSummaryView from '../Views/institution/InstitutionEvaluationSummaryView'
import SchoolModulesSettings from '../Views/institution/SchoolModulesSettings'
import ReportingView from '../Views/shared/ReportingView'
import InstitutionQuotasView from '../Views/institution/InstitutionQuotasView'
import { GlobalContext } from '../Providers/GlobalProvider'
import InstitutionSettingsView from '../Views/institution/Settings/InstitutionSettingsView'
import InstitutionProvider from '../Providers/InstitutionProvider'
import SchoolsView from '../Views/institution/SchoolsView'
import StudentsView from '../Views/institution/StudentsView'
import InternshipsView from '../Views/institution/InternshipsView/InternshipsView'
import SectorsView from '../Views/institution/SectorsView/SectorsView'
import ValidatorSectorsView from '../Views/school/validator/SectorsView'
import ContactPersonsTabsView from '../Views/shared/ContactPersons/ContactPersonsTabsView'

const mapStateToProps = state => ({ user: getUser(state.getUser) })

const InstitutionRouter = ({ history, moduleOption, user }) => {
  const { currentDate, setCurrentDate } = useContext(GlobalContext)

  const [selectedSector, setSelectedSector] = useState(null)

  const currentRole = useMemo(() => isArray(user.roles) ? user.roles[0] : OtherRoles.UNKNOWN, [user.roles])

  const goToShiftPresets = sector => {
    setSelectedSector(sector || null)
    history.push(Routes.PRESETS_MANAGER)
  }

  return (
    <InstitutionProvider>
        <Switch>
          <Route path={Routes.INTERNSHIPS}>
            <InternshipsView loadShiftsManager={setCurrentDate} />
          </Route>
          <Route path={Routes.STUDENTS}>
            <StudentsView />
          </Route>
          <Route path={Routes.SETTINGS}>
            <InstitutionSettingsView />
          </Route>
          <Route path={Routes.QUOTAS}>
            <InstitutionQuotasView />
          </Route>
          <Route path={Routes.BADGES}>
            <ReportingView />
          </Route>
          <Route path={Routes.MODULES_SETTINGS}>
            <SchoolModulesSettings moduleOption={moduleOption} />
          </Route>
          <Route path={Routes.SHIFTS_MANAGER}>
            <ShiftsManagerView
              currentDate={currentDate}
              displayManagePresets={goToShiftPresets}
            />
          </Route>
          <Route path={Routes.SCHOOLS}>
            <SchoolsView />
          </Route>
          {currentRole !== SchoolRoles.VALIDATOR && (
            <Route path={Routes.INSTITUTION_SECTORS}>
              <SectorsView displayManagePresets={goToShiftPresets} />
            </Route>
          )}
          {currentRole === SchoolRoles.VALIDATOR && (
            <Route path={Routes.INSTITUTION_SECTORS}>
              <ValidatorSectorsView />
            </Route>
          )}
          <Route path={Routes.PRESETS_MANAGER}>
            <ShiftPresetsManager
              sector={selectedSector}
            />
          </Route>
          <Route path={Routes.TAGS_MANAGEMENT}>
            <TagsManagementView />
          </Route>
          <Route path={Routes.CHANGELOG}>
            <ChangeLogView />
          </Route>
          {currentRole !== SchoolRoles.VALIDATOR && (
            <Route path={Routes.CONTACT_PERSONS}>
              <ContactPersonsTabsView />
            </Route>
          )}
          <Route path={Routes.STUDENTS_EVALUATION}>
            <Suspense fallback={<div>Loading...</div>}>
              <InstitutionEvaluationSummaryView />
            </Suspense>
          </Route>
        </Switch>
    </InstitutionProvider>
  )
}

export default withRouter(connect(mapStateToProps)(InstitutionRouter))
